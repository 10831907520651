/* Fonts  */
@font-face {
  font-family: 'Arial-MT-Bold';
  src: url('../public/assets/fonts/Arial-MT-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../public/assets/fonts/roboto-regular.woff') format('woff');
}

@font-face {
  font-family: 'Rubik';
  src: url('../public/assets/fonts/Rubik-Light.woff') format('woff');
}

@font-face {
  font-family: 'Acumin-thin';
  src: url('../public/assets/fonts/acumin-thin.woff') format('woff');
}

@font-face {
  font-family: 'Roboto-thin';
  src: url('../public/assets/fonts/roboto-thin.woff') format('woff');
}

@font-face {
  font-family: 'Roboto-Black';
  src: url('../public/assets/fonts/roboto-black.woff') format('woff');
}

@font-face {
  font-family: 'Oswald';
  src: url('../public/assets/fonts/oswald.woff') format('woff');
}

@font-face {
  font-family: 'Bebas-Regular';
  src: url('../public/assets/fonts/BebasNeue-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Poppins-ExtraLight';
  src: url('../public/assets/fonts/Poppins-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('../public/assets/fonts/Poppins-Light.woff') format('woff');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('../public/assets/fonts/Poppins-Medium.woff') format('woff');
}



:root {
  --background-purple: #19042c;
  --purple-bright: #37056f;
  --purple-bright-hover: #480e8a;
}

body {
  color: #fff;
  margin: 0px;
  overflow-y: scroll;
  background-color: #24023a;
  overflow-x: hidden;
}

img {
  user-select: none;
}

p,h1,h2,h3,h4 {
  padding: 0px;
  margin: 0px;
}

a {
  text-decoration: none;
}

body::-webkit-scrollbar {
  width: .5rem;  /* Adjust as needed */
  background: none;
}

body::-webkit-scrollbar-thumb {
  background-color: #8826ff;  /* Color of the thumb */
  border-radius: 0.5rem;  /* Rounded corners */
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #38ffa9;  /* Color on hover */
}



@keyframes slideTexter {
    0% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
  }

@keyframes growCircle {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  
  90% {
    transform: translate(-50%, -50%) scale(2.5);
  }

  100% {
    transform: translate(-50%, -50%) scale(2.5);
  }
}

.fade-in-words span.hidden {
  opacity: 0;
}

.fade-in {
  position: relative;
  animation: fadeIn .75s cubic-bezier(0.68, -0.75, 0.27, 1.55) forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
}

.active {
  background-color: #fff;
  box-shadow: 0px 0px 20px #fff;
  transition: all 1000ms ease;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

@keyframes gradientAnimationB {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
}